import React from "react";
import TextLoop from "react-text-loop";
import { FaFacebook, FaTwitter, FaYoutubeSquare } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaLinkedinIn } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import './slider.css';

const conctInfo = {
  phone: "(855) 936-7828",
  email: " Saurav.B@ArboHQ.com",
};

const sliderContent = {
  name: "Saurav Bhandari",
  description: ` Lorem ipsum dolor sit amet, consectetur adipiscing elit,
  sed do eiusmod tempor incididunt ut labore et dolore magna
  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  // btnText: " Donwload CV",


};

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="(855) 936-7828">{conctInfo.phone}</a>
            <a href="mailto:mail%20to:Saurav.B@ArboHQ.com,com">
              {conctInfo.email}
            </a>
          </div>
          <div className="hb-lang">
            <ul className="nav">
              <li className="active">
                <a href="#">EN</a>
              </li>
              {/* <li>
                <a href="#">FR</a>
              </li> */}
            </ul>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                {/* <h6>Hello, My name is</h6> */}
                <h1 className="font-alt" style={{ fontSize: "60px" }}>{sliderContent.name}</h1>
                <TextLoop>
                  <p className="loop-text lead">CPA</p>
                  <p className="loop-text lead">Entrepreneur</p>
                  <p className="loop-text lead">Explorer</p>
                </TextLoop>{" "}
                <p className="desc">{sliderContent.description}</p>
                <div className="mt-4">
                  <a
                    className="px-btn px-btn-white mb-4"
                    href="https://www.instagram.com/saurav.zen/"
                    target="_blank" rel="noopener noreferrer" >
                    <FaInstagram />
                  </a>
                  <a
                    className="px-btn px-btn-white mb-4"
                    href="https://www.youtube.com/"
                    target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                  </a>
                  <a
                    className="px-btn px-btn-white mb-4"
                    href="https://www.linkedin.com/in/saurav-bhandari-cpa
                    "
                    target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                  </a>
                  <a
                    className="px-btn px-btn-white mb-4"
                    href="https://www.facebook.com/saurav.bhandari.5"
                    target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                  </a>
                  <a
                    className="px-btn px-btn-white mb-4"
                    href="https://twitter.com/saurav_zenn/"
                    target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                  </a>




                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "img/slider/saurav.jpg"
              })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
