import React from "react";

const ServiceContent = [
  {
    icon: "icon-mobile",
    title: "Taxzen",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    link: "https://app.taxzen.com/loginSignup/"
  },
  {
    icon: "icon-desktop",
    title: "ArboHQ",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    link: "https://arbohq.com/"
  },
  {
    icon: "icon-target",
    title: "Arbo.CPA",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    link: "https://arbo.cpa/"
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (

          <div className="col-md-6 col-lg-4 my-3" key={i} >
            <a href={`${val.link}`} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }}>

              <div className="feature-box-01">
                <div className="icon">
                  <i className={`icon ${val.icon}`}></i>
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </div>
            </a>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
